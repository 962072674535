<template>
  <div class="content">
    <div class="head_title">用户协议</div>
    <div v-html="agreement"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreement: ""
    }
  },
	created(){
		this.loadAgreement()
	},
  methods: {
		//加载用户协议
		async loadAgreement(){
			const {data:res} = await this.$publicHttp.get("softwareinfo")
      if(res.meta.status !== "success") return this.$message.error("获取最新用户协议失败")
      this.agreement = res.data.softwareInfo.userAgreement
		},
  },
}
</script>

<style lang="less" scoped>
  .content{
    padding: 10px 20px;
  }
  .head_title{
    color: #333333;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .btns_area{
    margin: 20px auto;
    text-align: center;
  }
</style>